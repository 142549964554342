.about-text::selection {
    background-color: #F8F1F1;
    color: #0E0E0E;
  }

.logo-light::selection {
    background-color: #0E0E0E;
    color: #F8F1F1;
}

.logo-dark::selection {
  background-color: #F8F1F1;
  color: #0E0E0E;
}
